import type { EmailPreferenceWindow } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, Box, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { clientEmailMarketingPreferencesMessagesMessages } from '../../locales'

interface ConfirmationPageProps {
  confirmationText: string
}

export function ConfirmationPage({ confirmationText }: ConfirmationPageProps) {
  const { formatMessage } = useLocales()
  const { clientEmail } = preloaded
  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <CardSection maxWidth="500px" p="lm">
      <HStack>
        <Box pr="s" pt="xs">
          <Icon name="GX-checkbox-marked-circle" size="lg" color="successText" />
        </Box>
        <VStack spacing="xs">
          <Text textStyle={isMobile ? 'h2' : 'h1'}>
            {formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailMarketingPreferencesConfirmationPageTitle)}
          </Text>
          <SecondaryText>
            {formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailLabel)}
            {': '}
            {clientEmail}
          </SecondaryText>
          <Box pt="m">
            <Text fontSize={isMobile ? 'm' : 'l'}>{confirmationText}</Text>
          </Box>
        </VStack>
      </HStack>
    </CardSection>
  )
}

const preloadedData = (window as unknown as EmailPreferenceWindow).PRELOADED

const preloaded = {
  clientEmail: preloadedData.client_email,
}
