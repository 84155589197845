import { defineMessages } from '@sevenrooms/core/locales'

export const clientEmailMarketingPreferencesMessagesMessages = defineMessages({
  clientEmailMarketingPreferencesHeader: {
    id: 'clientEmailMarketingPreferencesHeader',
    defaultMessage: 'Manage Subscriptions',
  },
  clientEmailMarketingPreferencesDescription: {
    id: 'clientEmailMarketingPreferencesDescription',
    defaultMessage:
      'Select the locations you want to receive marketing emails from.\n\nToggle <strong>on</strong> to subscribe and toggle <strong>off</strong> to unsubscribe.',
  },
  clientEmailMarketingPreferencesSaveButton: {
    id: 'clientEmailMarketingPreferencesSaveButton',
    defaultMessage: 'Update Preferences',
  },
  clientEmailMarketingPreferencesUnsubscribeButton: {
    id: 'clientEmailMarketingPreferencesUnsubscribeButton',
    defaultMessage: 'Unsubscribe from All',
  },
  clientEmailMarketingPreferencesConfirmationPageTitle: {
    id: 'clientEmailMarketingPreferencesConfirmationPageTitle',
    defaultMessage: 'Email Preferences Updated',
  },
  clientEmailMarketingPreferencesConfirmationOptOut: {
    id: 'clientEmailMarketingPreferencesConfirmationOptOut',
    defaultMessage: 'We have updated your preferences',
  },
  clientEmailMarketingPreferencesConfirmationUnsubscribe: {
    id: 'clientEmailMarketingPreferencesConfirmationUnsubscribe',
    defaultMessage: 'You have unsubscribed from marketing communication from all of our locations',
  },
  clientEmailLabel: {
    id: 'resWidgetEmailLabel',
    defaultMessage: 'Email Address',
  },
  submissionError: {
    id: 'resConfirmPageError',
    defaultMessage: 'There seems to be an error',
  },
  allLocations: {
    id: 'allLocations',
    defaultMessage: 'All Locations',
  },
  unsubscribeFromAll: {
    id: 'unsubscribeFromAll',
    defaultMessage: 'Unsubscribe from All',
  },
} as const)
