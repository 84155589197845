import _ from 'lodash'
import { useMemo, useState } from 'react'
import { type EmailPreferenceWindow, useUpdateEmailPreferenceMutation } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Loader, VStack, Flex, notify, CardSection, Root } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { Box, Button, Divider, FormControlLabel, FormGroup, Stack, Switch } from '@sevenrooms/react-components'
import { clientEmailMarketingPreferencesMessagesMessages } from '../../locales'

interface ClientEmailMarketingPreferencesProps {
  toggleConfirmationStatus: (value: boolean) => void
  setConfirmationText: (value: string) => void
}

export function ClientEmailMarketingPreferences({ toggleConfirmationStatus, setConfirmationText }: ClientEmailMarketingPreferencesProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const { marketingOptIns: preloadedMarketingOptIns, clientId, clientEmail, campaignId, baseVenue, currentVenueOptIn } = preloaded
  const currentVenueId = baseVenue?.id
  const [updateEmailPreference, { isLoading }] = useUpdateEmailPreferenceMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Include current venue if it's not marketingOptIns
  const marketingOptIns = useMemo(() => {
    const hasOptInsCurrentVenue = _.find(preloadedMarketingOptIns, (_optIn, key) => key === currentVenueId)

    if (!hasOptInsCurrentVenue) {
      return { ...preloadedMarketingOptIns, [`${currentVenueId}`]: [baseVenue.name, currentVenueOptIn] }
    }

    return preloadedMarketingOptIns
  }, [preloadedMarketingOptIns, baseVenue, currentVenueOptIn, currentVenueId])

  const venueIds = Object.keys(marketingOptIns)

  const { preferenceOptions, venueOptIns } = useMemo(() => {
    const preferenceOptions: Array<{ value: string; label: string }> = []
    const venueOptIns: string[] = []

    venueIds.forEach(venueId => {
      const [venueName, isSubscribed] = marketingOptIns[venueId] as [string, boolean]
      const preferenceOption = {
        value: venueId,
        label: venueName,
      }
      preferenceOptions.push(preferenceOption)

      if (isSubscribed) {
        venueOptIns.push(venueId)
      }
    })

    const sortedPreferenceOptions = _.orderBy(preferenceOptions, option => _.toLower(option.label))

    return {
      preferenceOptions: sortedPreferenceOptions,
      venueOptIns,
    }
  }, [marketingOptIns, venueIds])

  const [selectedOptIns, setSelectedOptIns] = useState<Set<string>>(new Set(venueOptIns))

  const currentVenueOption = preferenceOptions.find(preference => preference.value === currentVenueId)

  const handlePreferenceChange = (value: string, checked: boolean) => {
    const newSelectedOptIns = checked
      ? new Set([...Array.from(selectedOptIns), value])
      : new Set(Array.from(selectedOptIns).filter(venue => venue !== value))

    setSelectedOptIns(newSelectedOptIns)
  }

  const onPreferenceUpdate = async () => {
    try {
      setIsSubmitting(true)

      await updateEmailPreference({
        clientId,
        venueOptIns: Array.from(selectedOptIns),
        venueOptOuts: venueIds.filter(venueId => !_.includes(Array.from(selectedOptIns), venueId)),
        campaignId,
        sendDoubleOptInEmail: true,
      })

      toggleConfirmationStatus(true)
      setConfirmationText(formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailMarketingPreferencesConfirmationOptOut))
    } catch {
      notify({
        content: formatMessage(clientEmailMarketingPreferencesMessagesMessages.submissionError),
        type: 'error',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const onUnsubscribeFromAll = async () => {
    try {
      setIsSubmitting(true)

      await updateEmailPreference({
        clientId,
        venueOptIns: Array.from([]),
        venueOptOuts: venueIds,
        campaignId,
        sendDoubleOptInEmail: true,
      })

      toggleConfirmationStatus(true)
      setConfirmationText(formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailMarketingPreferencesConfirmationOptOut))
    } catch {
      notify({
        content: formatMessage(clientEmailMarketingPreferencesMessagesMessages.submissionError),
        type: 'error',
      })
    } finally {
      setSelectedOptIns(new Set([]))
      setIsSubmitting(false)
    }
  }

  return (
    <Root theme="gx">
      {isSubmitting ? (
        <Loader />
      ) : (
        <CardSection maxWidth="500px" p="lm">
          <VStack spacing="lm">
            <VStack spacing="xs">
              <Text textStyle={isMobile ? 'h2' : 'h1'}>
                {formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailMarketingPreferencesHeader)}
              </Text>
              <SecondaryText whiteSpace="pre-line">
                {formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailMarketingPreferencesDescription, {
                  strong: (chunks: string[]) => <Text fontWeight="bold">{chunks}</Text>,
                })}
              </SecondaryText>
            </VStack>

            <Stack sx={{ maxHeight: 440, overflowY: 'auto', pl: 1 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={selectedOptIns.size === venueIds.length}
                      onChange={(_event, checked) => {
                        setSelectedOptIns(new Set(checked ? venueIds : []))
                      }}
                      inputProps={
                        {
                          'data-test': 'checkbox-all-locations',
                        } as React.InputHTMLAttributes<HTMLInputElement>
                      }
                    />
                  }
                  label={formatMessage(clientEmailMarketingPreferencesMessagesMessages.allLocations)}
                />

                {currentVenueOption && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={_.includes(Array.from(selectedOptIns), currentVenueOption.value)}
                        onChange={(_event, checked) => {
                          handlePreferenceChange(currentVenueOption.value, checked)
                        }}
                        inputProps={
                          {
                            'data-test': `checkbox-${currentVenueOption.value}`,
                          } as React.InputHTMLAttributes<HTMLInputElement>
                        }
                      />
                    }
                    label={baseVenue.name}
                  />
                )}

                <Divider sx={{ marginBottom: 2, marginTop: 2 }} />

                {preferenceOptions
                  .filter(preference => preference.value !== currentVenueId)
                  .map(preference => (
                    <FormControlLabel
                      key={preference.value}
                      control={
                        <Switch
                          checked={_.includes(Array.from(selectedOptIns), preference.value)}
                          onChange={(_event, checked) => {
                            handlePreferenceChange(preference.value, checked)
                          }}
                          inputProps={
                            {
                              'data-test': `checkbox-${preference.value}`,
                            } as React.InputHTMLAttributes<HTMLInputElement>
                          }
                        />
                      }
                      label={preference.label}
                    />
                  ))}
              </FormGroup>

              <Box mt={2}>
                <SecondaryText>
                  {formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailLabel)}
                  {': '}
                  {clientEmail}
                </SecondaryText>
              </Box>
            </Stack>

            <Flex flexDirection="row" rowGap="m" columnGap="m">
              <Button variant="contained" onClick={onPreferenceUpdate} disabled={isLoading} fullWidth data-test="update-preference-button">
                {formatMessage(clientEmailMarketingPreferencesMessagesMessages.clientEmailMarketingPreferencesSaveButton)}
              </Button>

              <Button
                onClick={() => {
                  onUnsubscribeFromAll()
                }}
                variant="outlined"
                disabled={isLoading}
                fullWidth
                data-test="unsubscribe-from-all-button"
              >
                {formatMessage(clientEmailMarketingPreferencesMessagesMessages.unsubscribeFromAll)}
              </Button>
            </Flex>
          </VStack>
        </CardSection>
      )}
    </Root>
  )
}

const preloadedData = (window as unknown as EmailPreferenceWindow).PRELOADED

const preloaded = {
  clientEmail: preloadedData.client_email,
  clientId: preloadedData.client_id,
  marketingOptIns: preloadedData.marketing_opt_ins,
  campaignId: preloadedData.campaign_id,
  baseVenue: preloadedData.base_venue,
  currentVenueOptIn: preloadedData.current_venue_opt_in,
}
