import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { type PropsWithChildren, useMemo, useState } from 'react'
import { Provider } from 'react-redux'
import { EmailPreferenceCenterAdapter, type EmailPreferenceWindow } from '@sevenrooms/core/api'
import { Locale } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Root, Flex, VStack, LogoFooter, TopNav, logoFooterMessages } from '@sevenrooms/core/ui-kit/layout'
import { CssBaseline } from '@sevenrooms/react-components'
import { clientEmailMarketingPreferencesMessagesMessages } from './locales'
import { ClientEmailMarketingPreferences, ConfirmationPage } from './views'

function AppRoot({ children }: PropsWithChildren<{}>) {
  const { venueInfo, selectedLanguageStrings, widgetSettings } = preloaded
  const { venueColor, locale } = venueInfo

  const brandColor = widgetSettings.colorPrimary
  const venueLocale = Locale.getLocale(locale)

  const messages = useMemo(
    () => ({
      ...selectedLanguageStrings,
      [clientEmailMarketingPreferencesMessagesMessages.allLocations.id]:
        clientEmailMarketingPreferencesMessagesMessages.allLocations.defaultMessage,
      [clientEmailMarketingPreferencesMessagesMessages.unsubscribeFromAll.id]:
        clientEmailMarketingPreferencesMessagesMessages.unsubscribeFromAll.defaultMessage,
      [logoFooterMessages.footerLabel.id]: selectedLanguageStrings.resWidgetPoweredByLabel,
    }),
    [selectedLanguageStrings]
  )

  const themeOverride = useMemo(
    () => ({
      colors: {
        brandColor,
        venueColor,
      },
    }),
    [brandColor, venueColor]
  )

  return (
    <Root theme="gx" themeOverride={themeOverride} messages={messages} locale={venueLocale}>
      {children}
    </Root>
  )
}

function EmailPreferenceContainer() {
  const { venueInfo } = preloaded
  const { website, name, largeLogoUrl } = venueInfo
  const isMobile = useMaxWidthBreakpoint('m')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [confirmationText, setConfirmationText] = useState('')

  return (
    <VStack justifyContent="space-between" minHeight="100vh">
      <VStack width="100%">
        <TopNav baseUrl={website} logoLabel={name} logo={largeLogoUrl} />
        <Flex width="100%" justifyContent="center" pt={isMobile ? 'l' : 'xxl'} pl="lm" pr="lm">
          {isSubmitted ? (
            <ConfirmationPage confirmationText={confirmationText} />
          ) : (
            <ClientEmailMarketingPreferences toggleConfirmationStatus={setIsSubmitted} setConfirmationText={setConfirmationText} />
          )}
        </Flex>
      </VStack>
      <LogoFooter />
    </VStack>
  )
}

const reducer = combineReducers([])
const store = configureStore({ reducer, preloadedState: {} })

export function App() {
  return (
    <Provider store={store}>
      <AppRoot>
        <CssBaseline>
          <EmailPreferenceContainer />
        </CssBaseline>
      </AppRoot>
    </Provider>
  )
}

const preloadedData = (window as unknown as EmailPreferenceWindow).PRELOADED

const preloaded = {
  venueInfo: EmailPreferenceCenterAdapter.venueToClient(preloadedData.base_venue),
  widgetSettings: EmailPreferenceCenterAdapter.settingsToClient(preloadedData.widget_settings),
  selectedLanguageStrings: EmailPreferenceCenterAdapter.stringsToClient(preloadedData.selected_language_strings),
  clientEmail: preloadedData.client_email,
}
